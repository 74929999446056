import orgConfig from "@/components/intel/levelConfigs/orgConfig";
import groupConfig from "@/components/intel/levelConfigs/groupConfig.js";
import advisorConfig from "@/components/intel/levelConfigs/advisorConfig.js";

export function getLevelConfig(level) {
  if (!level) {
    return;
  } else {
    let config;
    switch (level) {
      case "org":
        config = {
          id: "b8cd7d53-ca49-4372-94b7-e1e2f094a5a7",
          nav: orgConfig()[0].navigation,
        };
        break;
      case "group":
        config = {
          id: "e73ae766-89ba-4acd-aa36-f472278f35c0",
          nav: groupConfig()[0].navigation,
        };
        break;
      case "advisor":
        config = {
          id: "",
          nav: advisorConfig()[0].navigation,
        };
        break;
      default:
        config = null;
        break;
    }

    return config;
  }
}
