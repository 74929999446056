<template>
  <div>
    <header class="mb-6">
      <div class="d-flex align-center mb-2">
        <h1>Products</h1>
        <div class="ml-4 text-h6">
          <v-chip color="primary" small>{{ activeProducts.length }}</v-chip
          ><small class="ml-2">Active Product Recommendations</small>
        </div>
      </div>

      <p>
        Selected products will be available as recommendations for advisors to
        sell to qualified prospects.
      </p>
    </header>

    <v-row>
      <v-col class="col-12 col-sm-6 col-md-4 d-flex flex-column">
        <v-card
          color="#F1F3F5"
          class="flex-grow-1 d-flex flex-column"
          style="border: thin solid #4188ec"
          max-width="400"
        >
          <v-card-title class="flex-column justify-center h-100"
            ><div>
              <v-icon dark x-large color="primary darken-2">mdi-widgets</v-icon>
            </div>
            <v-btn outlined color="primary darken-4" class="mt-8"
              ><v-icon class="mr-2">mdi-plus</v-icon> New Product</v-btn
            >
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        v-for="p in products"
        :key="p.title"
        class="col-12 col-sm-6 col-md-4 d-flex flex-column"
      >
        <v-card
          class="flex-grow-1 d-flex flex-column rs-intel__product"
          :class="p.active ? 'is-active' : ''"
          outlined
          max-width="400"
        >
          <v-img class="white--text align-end" height="200px" :src="p.image">
          </v-img>

          <v-card-title>{{ p.title }}</v-card-title>

          <v-card-subtitle>{{ p.type }}</v-card-subtitle>

          <v-card-text class="pb-0">
            <p>{{ p.excerpt }}</p>
            <!-- <p class="mb-2 font-weight-bold">Risk Score Triggers:</p>
            <p>
              <v-chip-group column>
                <v-chip v-for="tag in p.tags" :key="tag" small>
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </p> -->
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-4"></v-divider>
          <v-card-actions class="flex-wrap">
            <v-btn text small>Learn More </v-btn>
            <v-btn
              :color="p.active ? 'error' : 'primary'"
              text
              small
              @click="handleToggle(p)"
              >{{ p.active ? "Remove " : "Add " }}{{ p.action_text }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { mapState, mapActions } from "vuex";
import { getLevelConfig } from "@/components/intel/services/getLevelConfig";
import { updateIntelOrg } from "@/graphql/mutations";

export default {
  data() {
    return {
      // activeProducts: [],
      products: [],
      config: null,
      loading: true,
    };
  },
  computed: {
    ...mapState(["currentIntel"]),
    activeProducts() {
      return this.products.filter((p) => {
        return p.active;
      });
    },
  },
  created() {
    const userLevel = localStorage.getItem("intelLevel");
    if (userLevel) {
      const config = getLevelConfig(userLevel);
      this.config = Object.assign({}, config, getLevelConfig);
    }
  },
  mounted() {
    this.products = this.currentIntel.products;

    console.log("act on mounted", this.activeProducts);
  },
  methods: {
    ...mapActions["currentIntel"],
    async handleToggle(product) {
      product.active = !product.active;
      console.log("product", product);

      try {
        const response = await API.graphql(
          graphqlOperation(updateIntelOrg, {
            input: { id: this.currentIntel.id, products: this.products },
          })
        );
        console.log("response", response);
      } catch (error) {
        console.log("error updating products");
      }
      // if (product.active) {
      //   this.activeProducts.push(product);

      //   newProductsArray = this.activeProducts;
      // } else {
      //   console.log("remove");
      //   newProductsArray = this.activeProducts.filter((p) => {
      //     return p.title !== product.title;
      //   });
      // }
      // const storeProducts = JSON.stringify(newProductsArray);
      // localStorage.setItem("intelActiveProducts", storeProducts);
      // console.log("new array", newProductsArray);
      // this.activeProducts = newProductsArray;
      // console.log("active Products", this.activeProducts);
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-intel {
  &__product {
    > div {
      transition: all 0.15s ease-in-out;
      &:not(.v-card__actions) {
        filter: grayscale(1);
        opacity: 0.5;
      }
    }

    &.is-active {
      box-shadow: 0 0 0 5px RGBA(65, 136, 236, 1);

      > div {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}
</style>
