const advisorConfig = () => [
  {
    navigation: {
      navItems: [
        {
          title: "Analytics",
          exact: true,
          disabled: false,
          to: "/intel",
          icon: "mdi-view-dashboard",
        },
        {
          title: "Resources",
          exact: true,
          disabled: false,
          to: "resources",
          icon: "mdi-hammer-wrench",
          items: [
            {
              title: "Sales Scripts",
              exact: true,
              disabled: false,
              to: { name: "IntelResourcesScripts" },
            },
            {
              title: "Support Videos",
              exact: true,
              disabled: false,
              to: { name: "IntelResourcesVideos" },
            },
            {
              title: "Score Methodology",
              exact: true,
              disabled: false,
              to: { name: "IntelResourcesMethodology" },
            },
          ],
        },
        {
          title: "Prospects",
          exact: true,
          disabled: false,
          to: { name: "List", query: { level: "prospect" } },
          icon: "mdi-account-group",
        },
        {
          title: "Survey Presenter",
          exact: true,
          disabled: false,
          to: { name: "IntelPresenter" },
          icon: "mdi-presentation",
        },
      ],
      navItems2: [
        {
          title: "Invite Prospect",
          exact: true,
          disabled: false,
          clickAction: "showProspectInviteDialog",
          icon: "mdi-email",
        },
        {
          title: "Upload Prospects",
          exact: true,
          disabled: true,
          to: "uploadProspects",
          icon: "mdi-upload",
        },
      ],
    },
  },
];
export default advisorConfig;
